import React from 'react';
import Layout from '../../component/Layout/Layout';
import OverView from './Overview';
import { get, isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import styles from './details.module.css'
import subscribeIcon from '../../assets/subscribe.gif'
import overviewIcon from '../../assets/details/overview.svg';
import activeOverviewIcon from '../../assets/details/overview-active.svg';
import returnIcon from '../../assets/details/return.svg';
import activeReturnIcon from '../../assets/details/return-active.svg';
import portfolioIcon from '../../assets/details/portfolio.svg';
import activePortfolioIcon from '../../assets/details/portfolio-active.svg';
import managementIcon from '../../assets/details/management.svg';
import activeManagementIcon from '../../assets/details/management-active.svg';
import feesIcon from '../../assets/details/fees.svg';
import activeFeesIcon from '../../assets/details/fees-active.svg';
import historicalIcon from '../../assets/details/historical.svg';
import activeHistoricalIcon from '../../assets/details/historical-active.svg';
import offeringIcon from '../../assets/details/offering.svg';
import activeOfferingIcon from '../../assets/details/offering-active.svg';

const { useState, useMemo, useEffect } = React;

const Details = ({setLocale}) => {

    let [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [tabsOptions, setTabsOptions] = useState([
        {
            key: 'overview',
            label: 'Overview',
            isActive: true,
            icon: overviewIcon,
            activeIcon: activeOverviewIcon,
            element: OverView,
        },
        {
            key: 'return',
            label: 'Return and Risk',
            isActive: false,
            icon: returnIcon,
            activeIcon: activeReturnIcon,
            element: () => (<div></div>),
        },
        {
            key: 'portfolio',
            label: 'Portfolio',
            isActive: false,
            icon: portfolioIcon,
            activeIcon: activePortfolioIcon,
            element: () => (<div></div>),
        },
        {
            key: 'management',
            label: 'Management',
            isActive: false,
            icon: managementIcon,
            activeIcon: activeManagementIcon,
            element: () => (<div></div>),
        },
        {
            key: 'fees',
            label: 'Fees',
            isActive: false,
            icon: feesIcon,
            activeIcon: activeFeesIcon,
            element: () => (<div></div>),
        },
        {
            key: 'historical',
            label: 'Historical Price & Dividend',
            isActive: false,
            icon: historicalIcon,
            activeIcon: activeHistoricalIcon,
            element: () => (<div></div>),
        },
        {
            key: 'offering',
            label: 'Offering Documents',
            isActive: false,
            icon: offeringIcon,
            activeIcon: activeOfferingIcon,
            element: () => (<div></div>),
        },
    ]);

    const requestParams = useMemo(() => {
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        return params;
    }, [searchParams]);

    const topInfoOptions = useMemo(() => {
        if (isEmpty(data)) {
            return []
        }

        return [
            {
                key: 'Fund',
                value: get(data, 'detail.LegalName'),
                color: '#CE0000',
            },
            {
                key: 'Category',
                value: get(data, 'detail.CategoryName'),
                color: '#FF9A63',
            },
            {
                key: 'Benchmark',
                value: get(data, 'detail.CategoryPrimaryIndexName'),
                color: '#99CF00',
            }
        ]
    }, [data]);

    const isHideCloseSubscribe = useMemo(() => {
        const status = get(requestParams, 'CustomAttributes2') || '';
        
        return status.toUpperCase() === 'YES';
    }, [requestParams]);

    const currentTabName = useMemo(() => {
        return tabsOptions.filter(item => item.isActive)[0].label
    }, [tabsOptions]);

    const onUpdateTab = (key) => {
        setTabsOptions(tabsOptions.map(item => {
            return {
                ...item,
                isActive: item.key === key
            }
        }))
    };

    useEffect(() => {
        onRequest(requestParams);
    }, [requestParams]);

    const onRequest = async ({SecId, currencyId, universeIds}) => {
        setLoading(true)
        try {
            const response = await fetch(encodeURI(`api2/v1/agent/security/screener/${SecId}?currencyId=${currencyId}&universeIds=${universeIds}`), {// /v1/agent
                method: 'GET'
            });
            const res = await response.json();
            if (res.fundChatCode) {
                setData(res);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err)
        }
    };

    const onGoto = () => {
        // window.open('https://myebankasia.icbc.com.cn/icbc/perbank/index.jsp?areaCode=0110&dse_locale=en-US', '_blank')
    };

    return (
        <Layout 
            loading={loading} 
            setLocale={setLocale}
            isShowTabs={true}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles['header-top']}>
                        <h1>Morningstar® Fund Report™</h1>
                    </div>
                    <div className={styles['header-body']}>
                        <h1 
                            className={styles['header-body-title']}
                            style={{
                                marginBottom: isHideCloseSubscribe && 18
                            }}
                        >
                            {get(data, 'detail.LegalName')}
                            {
                                isHideCloseSubscribe && (
                                    <span className={styles['header-body-title-tips']}>
                                        (closed for subscription)
                                    </span>
                                )
                            }
                        </h1>

                        <div className={styles['header-body-group']}>
                            <span className={styles['header-body-group-title']}>
                                {currentTabName}
                            </span>
                            <div  
                                className={`${styles['header-body-link']} ${isHideCloseSubscribe && styles['header-body-link-disabled']}`}
                                onClick={isHideCloseSubscribe ? null : onGoto}
                            >
                                <img src={subscribeIcon}/>
                                <span>Subscribe</span>
                                <div className={styles['header-body-link-bg']} style={{display: isHideCloseSubscribe && 'none'}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles['header-info']}>
                        {
                            topInfoOptions.map(({key, value, color}) => {
                                return (
                                    <div 
                                        key={`topInfo_${key}`}
                                        className={styles['header-info-item']}
                                    >
                                        <span>{key}:</span>
                                        <span>{value}</span>
                                        <div className={styles['header-info-item-round']} style={{background: color}}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <MobileTabs tabsOptions={tabsOptions} onUpdateTab={onUpdateTab}/>
                <div className={styles.tabs}>
                {
                    tabsOptions.map(({key, label, isActive, icon, activeIcon}) => (
                        <button
                            key={key}
                            className={`${styles['tabs-item']} ${isActive ? styles['tabs-item-active'] : ''}`}
                            onClick={() => onUpdateTab(key)}
                            tabIndex="0"
                            role="button"
                        >
                            <img src={isActive ? activeIcon : icon}/>
                            <span>{label}</span>
                        </button>
                    ))
                }
                </div>
                {
                    tabsOptions.map(({isActive, element, key}) => {
                        if (isActive) {
                            const Element = element;
                            return (
                                <Element
                                    key={`page_${key}`}
                                    data={data}
                                />
                            )
                        }
                    })
                }
        </div>
        </Layout>
    )
};

const MobileTabs = ({tabsOptions, onUpdateTab}) => {
    return (
        <div className={styles['mobile-tabs']}>
            {
                tabsOptions.map(({key, label, isActive, icon, activeIcon}) => (
                    <div 
                        key={`${key}-mobile`}
                        className={`${styles['mobile-tabs-item']} ${isActive ? styles['mobile-tabs-item-active'] : ''}`}
                        role="button"
                        onClick={() => onUpdateTab(key)}
                    >
                        <img src={isActive ? activeIcon : icon}/>
                        <span className={styles['mobile-tabs-item-label']}>{label}</span>
                    </div>
                ))
            }
        </div>
    )
}

export default Details;