import React from 'react';
import AllocationChart from './InfoChart';
import { StarBox } from '../Details/Overview';
import anime from 'animejs';
import closeIcon from '../../assets/xray/close.svg';
import styles from './info.module.css';
import { 
    getKeyInformationData, getPerformanceData, 
    getVolatilityData, getDetailsData, getPortfolioData 
} from '../../utils/fundComparison';

const { useMemo, useState, useRef, useEffect } = React;

const Info = ({
    data,
    setData
}) => {

    const headerOptions = useMemo(() => {
        return [{
            key: 'header-0-key',
            isNull: true
        }].concat(data.map((item, index) => {
            return {
                key: `header-${index + 1}-key-${item.Isin}`,
                name: item.Name,
                iSin: item.Isin
            }
        }))
    }, [data]);

    const keyInformationOptions = useMemo(() => {
        return {
            title: 'Key Information',
            data: getKeyInformationData(data)
        }
    }, [data]);

    const performanceOptions = useMemo(() => {
        return {
            title: 'Performance',
            data: getPerformanceData(data)
        }
    }, [data]);

    const volatilityOptions = useMemo(() => {
        return {
            title: 'Volatility',
            data: getVolatilityData(data)
        }
    }, [data]);

    const portfolioOptions = useMemo(() => {
        return {
            title: 'Portfolio',
            data: getPortfolioData(data)
        }
    }, [data]);

    const detailsOptions = useMemo(() => {
        return {
            title: 'Details',
            data: getDetailsData(data)
        }
    }, [data]);

    const onDelete = (key) => {
        setData(data.filter(item => {
            return item.Isin !== key
        }));
    };

    return (
        <div className={styles['info-container']}>
            <div className={styles['info-header']}>
                {
                    headerOptions.map(({key, name, iSin, isNull}, index) => {
                        if (isNull) {
                            return (
                                <div key={key} style={{borderRight: index === 0 && '1px solid #999', minWidth: '168px'}}/>
                            )
                        }
                        
                        return (
                            <div className={styles['header-item']} key={key}>
                                <span>{name}</span>
                                <div className={styles['header-item-action']}>
                                    <span className={styles['header-item-isin']}>{iSin}</span>
                                    <img 
                                        src={closeIcon} 
                                        className={styles['header-item-delete']} 
                                        tabIndex={0} 
                                        role="button"
                                        onClick={() => onDelete(iSin)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Collapse options={keyInformationOptions} columns={data.length + 1}/>
            <Collapse options={performanceOptions} columns={data.length + 1}/>
            <Collapse options={volatilityOptions} columns={data.length + 1}/>
            <Collapse options={portfolioOptions} columns={data.length + 1}/>
            <Collapse options={detailsOptions} columns={data.length + 1}/>
        </div>
    )
};

const Collapse = ({options, columns = 1}) => {
    const elementRef = useRef(null);

    const [isOpen, setIsOpen] = useState(true);

    const onStartAnimation = (isOpen) => {
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    height: '100%',
                    duration: 0,
                    easing: 'linear'
                },
                {
                    scaleY: isOpen ? 1 : 0,
                    duration: 300,
                    easing: 'linear',
                    transformOrigin: 'top'
                },
                {
                    height: isOpen ? '100%' : '0px',
                    duration: 0,
                    easing: 'linear',
                }
            ],
        });
    }

    const onClickHeader = () => {
        setIsOpen(!isOpen);
        onStartAnimation(!isOpen);
    };

    return (
        <div className={styles['panel-container']} style={{minWidth: `${columns * 169}px`}}>
            <div 
                className={styles['panel-header']} 
                onClick={onClickHeader}
                tabIndex="0"
            >
                <span>{options.title}</span>
                <div className={`${styles['panel-header-icon']} ${!isOpen && styles['panel-header-icon-close']}`}/>
            </div>
            <div className={styles['panel-collapse']} ref={elementRef}>
                {
                    options.data.map((
                        { key, value, type, isBold, color, icon, iconStyle, headerIcon }, i
                    ) => (
                        <div 
                            className={styles['collapse-row']} 
                            key= {`${key}-${i}`} 
                            style={{
                                gridTemplateColumns: isBold ? '1fr' : ''
                            }}
                        >
                            <div 
                                className={styles['collapse-row-header']}
                                style={{fontWeight: isBold ? '700' : '', borderRight: isBold ? 'none' : ''}}
                            >   
                                {
                                    headerIcon && (
                                        <div className={styles['collapse-row-header-icon']} style={headerIcon.iconStyle}>
                                            <img src={headerIcon.icon} style={{width: headerIcon.width}}/>
                                        </div>
                                    )
                                }
                                {
                                    icon && <img src={icon} style={{...iconStyle, alignSelf: 'center', width: 28, height: 28}}/>
                                }
                                {
                                    color && (
                                        <div style={{width: 5, height: 5, border: `5px solid ${color}`, marginRight: 6, borderRadius: '100%', alignSelf: 'center'}}/>
                                    )
                                }
                                <span style={{alignSelf: icon || headerIcon ? 'center' : ''}}>{key}</span>
                            </div>
                            {
                                value.map((item, index) => (
                                    <div 
                                        className={styles['collapse-row-item']} 
                                        key={`${key}-${i}-${index}`}
                                    >
                                        {
                                            type === 'rating' && (
                                                <StarBox 
                                                    value={item} 
                                                    isShowNull={true}
                                                    id='morningstarRating'
                                                />
                                            )
                                        }
                                        {
                                            type === 'allocation' && (
                                                <AllocationChart data={item} chartKey={`${key}-${Math.random()}`}/>
                                            )
                                        }
                                        {
                                            type ==='style' && (
                                                <StyleBox value={item}/>
                                            )
                                        }
                                        {
                                            (type !== 'rating') && (type !== 'allocation') && (type !== 'style') && (
                                                <span>{item}</span>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const StyleBox = ({value}) => {

    const dataList = useMemo(() => {
        const list = [
            {
                key: 'Large Value',
                value: 0
            },
            {
                key: 'Large Blend',
                value: 1
            },
            {
                key: 'Large Growth',
                value: 2
            },
            {
                key: 'Mid Value',
                value: 3
            },
            {
                key: 'Mid Blend',
                value: 4
            },
            {
                key: 'Mid Growth',
                value: 5
            },
            {
                key: 'Small Value',
                value: 6
            },
            {
                key: 'Small Blend',
                value: 7
            },
            {
                key: 'Small Growth',
                value: 8
            }
        ];

        return list.map(item => {
            if (item.value === value) {
                return {
                    ...item,
                    isActive: true,
                }
            }

            return item;
        })
    }, [value]);

    const boxInfoText = useMemo(() => {
        const data = dataList.filter(item => item.isActive);

        if (data.length === 0) return '-';
        
        return data[0].key
    }, [dataList])

    return (
        <div className={styles['style-box-container']}>
            
            <div className={styles['box-container']}>
                {
                    dataList.map((item, index) => (
                        <div 
                            className={styles['box-container-item']}
                            style={{
                                borderLeft: index === 0 || index === 3 || index === 6 ? '1px solid #000' : '',
                                borderBottom: index === 6 || index === 7 || index === 8 ? '1px solid #000' : '',
                                background: item.isActive ? '#000' : '#fff'
                            }}
                            key={item.key}
                        />
                    ))
                }
            </div>
            <div className={styles['box-info']}>
                {boxInfoText}
            </div>  
        </div>
    )
};

export default Info;