import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './index.module.css';
import starIcon from '../../assets/select/star.svg';
import ratingIcon from '../../assets/select/rating.svg'; 

const MorningstarRatingGroup = ({
    options = [],
    onChange = () => {}
}) => {
    return  (
        <div className={styles.container}>
            {
                options.map(({key, label, options, icon, isStar, messageId}, index) => {
                    return (
                        <div
                            key={key}
                            className={styles.item}
                        >
                            <div className={styles.header}>
                                <img src={icon} alt={label}/>
                                <span>
                                    <FormattedMessage id={messageId}/>
                                </span>
                            </div>
                            <div className={styles.body}>
                            {
                                options.map(({key, checked, id}, optionsIndex) => {
                                    const imgArr = Array.from(Array(id), (e, index) => index + 1)
                                    return (
                                        <div key={key} className={styles['body-item']}>
                                            <input 
                                                className={styles.checkbox} 
                                                checked={checked}
                                                name={key} 
                                                type="checkbox"
                                                onChange={(e) => onChange(e, index, optionsIndex)}
                                            />
                                            {
                                                imgArr.map((item) => {
                                                    return (
                                                        <img  
                                                            src={isStar ? starIcon : ratingIcon}
                                                            key={`${key}-${item}`}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default MorningstarRatingGroup;