import React from 'react';
import { ShepherdTour, TourMethods } from "react-shepherd";
import { isNumber, isEmpty, ceil, divide, get, isEqual, round } from 'lodash';
import { useNavigate, createSearchParams } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { returnFloat } from '../../utils/details';
import { LOCALES } from '../../locales'
import Table from './Table';
import styles from './tabs.module.css';
import starIcon from '../../assets/select/star.svg';
import ratingIcon from '../../assets/select/rating.svg'; 
import documentIcon from '../../assets/select/document.png';
import selectIcon from '../../assets/select/select.png';
import fundComparisonIcon from '../../assets/layout_action_1_2.png';
import "shepherd.js/dist/css/shepherd.css";

const formatNumber = (value) => {
    return isNumber(value) ? returnFloat(round(value, 2)) : value
};

const tourOptions = {
    defaultStepOptions: { 
        showCancelLink: true,
    },
    useModalOverlay: true,
    keyboardNavigation: false
};

const steps = [
    {
        id: "intro",
        attachTo: {
            element: "#action-button",
            on: "top"
        },
        scrollTo: {behavior: 'smooth', block: 'center'},
        buttons: [
            {
                classes: "shepherd-button-secondary",
                text: "Exit",
                type: "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text: "Next",
                type: "next"
            }
        ],
        classes: `custom-class-name-1 custom-class-name-3 ${styles['steps-container']}`,
        highlightClass: "highlight",
        showCancelLink: true,
        text: [
            `<div style="font-family: 'Inter'; text-align: center;">Select fund(s) to view portfolio X-Ray<div>`
        ],
    },
    {
        id: "second",
        attachTo: {
            element: "#cell-legal-name-0",
            on: "bottom"
        },
        scrollTo: {behavior: 'smooth', block: 'center'},
        buttons: [
            {
                classes: "shepherd-button-secondary",
                text: "Exit",
                type: "cancel"
            },
        ],
        classes: `custom-class-name-1 custom-class-name-3 ${styles['steps-second-container']}`,
        highlightClass: "highlight",
        showCancelLink: false,
        text: [
            `<div style="font-family: 'Inter'; text-align: center;">Click on Fund name to see detailed information<div>`
        ],
    },
];

const { useState, useMemo, useEffect } = React;

const Start = ({startTour, data, isStart, setIsStart = () => {}}) => {

    useEffect(() => {
        if (!isStart && !(isEmpty(data))) {
            startTour.start()
            setIsStart(true)
        }

        return () => {
            return startTour.complete();
        }
    }, [data]);

    return null
};

const Tabs = ({
    params,
    setParams,
    onRequestListData,
    data,
    intl,
    isStart,
    setIsStart
}) => {
    const headerNameEnum = {
        'custominstitutionsecurityid': 'tableHeaderFundCode',
        'LegalName': 'tableHeaderFundName',
        'GlobalCategoryName': 'tableHeaderFundCategory',
        'CustomCategoryId': 'tableHeaderRiskRating',
        'Rating': 'tableHeaderMorningstarRating',
        'SustainabilityRank': 'tableHeaderMorningstarSustainability',
        'Currency': 'tableHeaderFundCurrency',
        'ReturnM0': 'tableHeaderYTDReturn',
        'closePrice': 'tableHeaderLastFund',
        'Yield_M12': 'tableHeaderYieldM12',
        'ClosePriceDate': 'tableHeaderLastPrice',
        'ReturnM1': 'tableHeaderReturnM1',
        'ReturnM3': 'tableHeaderReturnM3',
        'ReturnM6': 'tableHeaderReturnM6',
        'ReturnM12': 'tableHeaderReturnM12',
        'ReturnM36UA': 'tableHeaderReturnM36UA',
        'ReturnM60UA': 'tableHeaderReturnM60UA',
        'YR_ReturnM12_5': 'tableHeaderYRReturnM12_5',
        'YR_ReturnM12_4': 'tableHeaderYRReturnM12_4',
        'YR_ReturnM12_3': 'tableHeaderYRReturnM12_3',
        'YR_ReturnM12_2': 'tableHeaderYRReturnM12_2',
        'YR_ReturnM12_1': 'tableHeaderYRReturnM12_1',
        'StandardDeviationM12': 'tableHeaderStandardDeviationM12',
        'StandardDeviationM36': 'tableHeaderStandardDeviationM36',
        'StandardDeviationM60': 'tableHeaderStandardDeviationM60',
        'SharpeM12': 'tableHeaderSharpeM12',
        'SharpeM36': 'tableHeaderSharpeM36',
        'SharpeM60': 'tableHeaderSharpeM60',
        'FS': 'tableHeaderFS',
        'PR': 'tableHeaderPR',
        'KF': 'tableHeaderKF',
        'AR': 'tableHeaderAR',
        'IR': 'tableHeaderIR'
    };

    const navigate = useNavigate();

    const [selectRowIds, setSelectRowIds] = useState([]);
    const [selectRows, setSelectRows] = useState([]);

    const [tabsOptions, setTabsOptions] = useState([
        {
            key: 'overview',
            messageId: 'landingTabsOverview',
            fields: [
                'custominstitutionsecurityid', 'LegalName', 'GlobalCategoryName', 'CustomCategoryId',
                'Rating', 'SustainabilityRank', 'Currency', 'ReturnM0',
                'closePrice', 'Yield_M12', 'ClosePriceDate'
            ],
            isActive: true
        },
        {
            key: 'cumulativePerformance',
            messageId: 'landingTabsCumulativePerformance',
            fields: [
                'custominstitutionsecurityid', 'LegalName', 'GlobalCategoryName', 'CustomCategoryId',
                'ReturnM0', 'ReturnM1', 'ReturnM3', 'ReturnM6',
                'ReturnM12', 'ReturnM36UA', 'ReturnM60UA', 'ClosePriceDate'
            ],
            isActive: false
        },
        {
            key: 'calendarYearPerformance',
            messageId: 'landingTabsCalendarYearPerformance',
            fields: [
                'custominstitutionsecurityid', 'LegalName', 'GlobalCategoryName', 'CustomCategoryId',
                'YR_ReturnM12_5', 'YR_ReturnM12_4', 'YR_ReturnM12_3', 'YR_ReturnM12_2', 'YR_ReturnM12_1'
            ],
            isActive: false
        },
        {
            key: 'riskAndReturn',
            messageId: 'landingTabsRiskAndReturn',
            fields: [
                'custominstitutionsecurityid', 'LegalName', 'GlobalCategoryName', 'CustomCategoryId',
                'StandardDeviationM12', 'StandardDeviationM36', 'StandardDeviationM60',
                'SharpeM12', 'SharpeM36', 'SharpeM60'
            ],
            isActive: false
        },
        {
            key: 'documents',
            messageId: 'landingTabsDocuments',
            fields: [
                'custominstitutionsecurityid', 'LegalName', 'GlobalCategoryName', 'CustomCategoryId',
                'FS', 'PR', 'KF', 'AR', 'IR'
            ],
            isActive: false
        },
    ]);

    const tableTotal = useMemo(() => {
        return isNumber(data.total) ? data.total : '';
    }, [data]);

    const tableCount = useMemo(() => {
        const {pageSize, total} = data;
        if (isNumber(total)) {
            return ceil(divide(total, pageSize));
        }

        return 0
    }, [data]);

    const tableColumns = useMemo(() => {
        const activeTab = tabsOptions.filter((item) => item.isActive)[0];
        const columns = activeTab.fields.map(key => {
            if (key === 'Rating' || key === 'SustainabilityRank') {
                return {
                    Header: intl.formatMessage({id: headerNameEnum[key]}),
                    accessor: key,
                    Cell: ({ cell }) => {
                        if (!isNumber(cell.value)) {
                            return <span>-</span>
                        }
                        const arr = Array.from(Array(cell.value), (e, index) => index + 1);
                        return (
                            <div className={styles['columns-rating']}>
                                {
                                    arr.map(item => (
                                        <img 
                                            src={key === 'Rating' ? starIcon : ratingIcon}
                                            key={`${item}-${key}`}
                                        />
                                    ))
                                }

                            </div>
                        )
                    }
                }
            }

            if (key === 'LegalName') {
                return {
                    Header: intl.formatMessage({id: headerNameEnum[key]}),
                    accessor: key,
                    Cell: (props) => {
                        const { cell, row: {original, isSelected, index} } = props;
                        return (
                            <div 
                                id={`cell-legal-name-${index}`}
                                className={`${styles['columns-link']} ${isSelected && styles['columns-link-active']}`}
                                tabIndex="1"
                                role="button"
                                onClick={() => onGotoDetails(original, navigate)}
                            >
                                <span>{cell.value}</span>
                                <div 
                                    style={{
                                        fontSize: '12px',
                                        color: 'red',
                                        display: (get(original, 'CustomAttributes2') || '').toUpperCase() === 'YES' ? 'block' : 'none'
                                    }}
                                >
                                    <FormattedMessage id='tableRowsClose'/>
                                </div>
                            </div>
                        )
                    }
                }
            }

            if (key === 'FS' || key === 'PR' || key === 'KF' || key === 'AR' || key === 'IR') {
                return {
                    Header: intl.formatMessage({id: headerNameEnum[key]}),
                    accessor: key,
                    Cell: (props) => {
                        return (
                            <div className={styles['columns-document']}>
                                <img 
                                    src={documentIcon}
                                    onClick={() => onOpenDocument(get(props, 'row.original.SecId'), get(props, 'column.id'), intl.locale)}
                                />
                            </div>
                        )
                    }
                }
            }

            return {
                Header: intl.formatMessage({id: headerNameEnum[key]}),
                accessor: key,
            }
        });
        return columns;
    }, [tabsOptions, intl, navigate]);

    const tableData = useMemo(() => {
        return isEmpty(data.rows) ? [] : data.rows.map(item => {
            const { 
                ReturnM0, closePrice, Yield_M12, 
                ReturnM1, ReturnM3, ReturnM6, ReturnM12, ReturnM36UA, ReturnM60UA,
                YR_ReturnM12_5, YR_ReturnM12_4, YR_ReturnM12_3, YR_ReturnM12_2, YR_ReturnM12_1,
                StandardDeviationM12, StandardDeviationM36, StandardDeviationM60, 
                SharpeM12, SharpeM36, SharpeM60
            } = item;
            return {
                ...item,
                ReturnM0: formatNumber(ReturnM0),
                closePrice: formatNumber(closePrice),
                Yield_M12: formatNumber(Yield_M12),
                ReturnM1: formatNumber(ReturnM1), 
                ReturnM3: formatNumber(ReturnM3), 
                ReturnM6: formatNumber(ReturnM6),
                ReturnM12: formatNumber(ReturnM12), 
                ReturnM36UA: formatNumber(ReturnM36UA), 
                ReturnM60UA: formatNumber(ReturnM60UA),
                YR_ReturnM12_5: formatNumber(YR_ReturnM12_5),
                YR_ReturnM12_4: formatNumber(YR_ReturnM12_4),
                YR_ReturnM12_3: formatNumber(YR_ReturnM12_3),
                YR_ReturnM12_2: formatNumber(YR_ReturnM12_2),
                YR_ReturnM12_1: formatNumber(YR_ReturnM12_1),
                StandardDeviationM12: formatNumber(StandardDeviationM12),
                StandardDeviationM36: formatNumber(StandardDeviationM36),
                StandardDeviationM60: formatNumber(StandardDeviationM60),
                SharpeM12: formatNumber(SharpeM12),
                SharpeM36: formatNumber(SharpeM36),
                SharpeM60: formatNumber(SharpeM60),
            }
        });
    }, [data]);

    const onUpdateTab = (key) => {
        setTabsOptions(tabsOptions.map(item => {
            return {
                ...item,
                isActive: item.key === key
            }
        }))
    };

    const onGotoDetails = (original, navigate) => {
        navigate({
            pathname: '/details',
            search: `${createSearchParams({
                SecId: get(original, 'SecId'),
                universeIds: get(original, 'Universe'),
                currencyId: get(original, 'Currency'),
                CustomAttributes2: get(original, 'CustomAttributes2') || null
            })}`
        });
    };

    const onGoto = () => {
        if (selectRowIds.length > 0) {
            navigate({
                pathname: '/portfolioXray',
                search: encodeURI(`?ids=${selectRowIds}`)
            });
        }
    };

    const onGotoFund = () => {
        if (selectRowIds.length > 0 && selectRowIds.length <= 5) {
            navigate({
                pathname: '/fundComparison',
                search: encodeURI(`?ids=${selectRowIds}`)
            });
            return
        }
        if (selectRowIds.length > 5) {
            alert('Please select up to 5 funds.')
        }
    };

    const onTableFetchChange = (pageIndex, pageSize, sortBy) => {
        const {id, desc} = get(sortBy, '0') || {};
        const newParams = {
            ...params,
            page: pageIndex + 1,
            pageSize,
            sortOrder: `${id} ${desc ? 'desc' : 'asc'}`
        };
        setParams(newParams);
        onRequestListData(newParams);
    };

    const onSelectRow = (selectIds, selectRow) => {
        const arr = Object.keys(selectIds).map((name) => {
            for(let i = 0; i < selectRow.length; i++) {
                const item = selectRow[i];
                if (item.original.SecId === name) {
                    return item.original
                }
            }

            for(let i = 0; i < selectRows.length; i++) {
                const item = selectRows[i];
                if (item.SecId === name) {
                    return item
                }
            }
        });
        if (isEqual(arr, selectRows)) return

        setSelectRows(arr);
    };

    const onSelectTableData = (selectIds, selectRow) => {
        const arr = Object.keys(selectIds);

        if (isEqual(arr, selectRowIds)) return;

        setSelectRowIds(arr);
        onSelectRow(selectIds, selectRow);
    };

    const onOpenDocument = (id, type, locale) => {
        const languageId = (locale === 'zh-HK' || locale === 'zh-CN') ? 'CH' : 'EN';
        window.open(`http://asiaapi.morningstar.com/ODSHelperWS/default.aspx?Id=${id}&DocType=${type}&ClientId=icbcasia&MarketId=CU$$$$$HKG&LanguageId=${languageId}`)
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {
                    tabsOptions.map(({key, messageId, isActive}, index) => (
                        <button
                            key={key}
                            className={`${styles['header-item']} ${isActive ? styles['header-item-active'] : ''}`}
                            onClick={() => onUpdateTab(key)}
                            tabIndex="0"
                            role="button"
                            style={{
                                fontFamily: (intl.locale === LOCALES.ENGLISH || intl.locale === LOCALES.CHINESE) ? 'Inter' :'Noto Sans HK'
                            }}
                        >
                            <FormattedMessage id={messageId}/>
                        </button>
                    ))
                }
            </div>
            <MobileAction 
                tabsOptions={tabsOptions}
                onUpdateTab={onUpdateTab}
            />
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <TourMethods>
                    {tourContext => <Start startTour={tourContext} data={data} isStart={isStart} setIsStart={setIsStart}/>}
                </TourMethods>
            </ShepherdTour>
            <div className={styles.action}>
                <span className={styles['action-title']}>
                    {selectRowIds.length}/{tableTotal}
                </span>
                <div onClick={onGotoFund}>
                    <img 
                        src={fundComparisonIcon} 
                        width="18" 
                        style={{transform: 'translateY(2px)'}} 
                        alt="Fund Comparison" 
                        title="Fund Comparison"
                    /> 
                </div>
                <div
                    // className={styles['action-button']}
                    id='action-button'
                    onClick={onGoto}
                >
                    <img 
                        src={selectIcon}
                        alt="Portfolio X-Ray"
                        title="Portfolio X-Ray"
                    />    
                </div>
            </div>
            <div className={styles.list}>
                <Table 
                    data={tableData}
                    columns={tableColumns}
                    totalCount={tableCount || 0}
                    totalData={tableTotal}
                    onFetchData={onTableFetchChange}
                    controlledPageIndex={params.page - 1}
                    onSelectRow={onSelectTableData}
                />
            </div>
        </div>
    )
};

const MobileAction = ({tabsOptions, onUpdateTab}) => {
    return (
        <div className={styles['header-mobile']}>
            {
                tabsOptions.map(({key, messageId, isActive}) => (
                    <button
                        key={`${key}-mobile`}
                        className={`${styles['mobile-header-item']} ${isActive ? styles['mobile-header-item-active'] : ''}`}
                        onClick={() => onUpdateTab(key)}
                        tabIndex="0"
                    >
                        <span className={styles['mobile-header-item-label']}><FormattedMessage id={messageId}/></span>
                    </button>
                ))
            }
        </div>
    )

};

export default injectIntl(Tabs);