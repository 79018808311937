import React from 'react';
import { isEmpty, isNumber, round } from 'lodash';
import { getAssetChartData, getAssetBarChartData } from '../../utils/fundComparison';
import { returnFloat } from '../../utils/details';
import * as echarts from 'echarts';
import styles from './info-chart.module.css';
import { Decimal } from 'decimal.js';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const { useState, useRef, useEffect, useMemo } = React;

const Chart = ({
    data,
    chartKey
}) => {

    const [status, setStatus] = useState(true);

    return (
        <div className={styles.container}>
            <div className={styles['button-group']}>
                <button 
                    className={`${styles.button} ${styles['button-left']} ${status && styles['button-action']}`}
                    onClick={() => setStatus(true)}
                >
                    Pie Chart
                </button>
                <button 
                    className={`${styles.button} ${styles['button-right']} ${!status && styles['button-action']}`}
                    onClick={() => setStatus(false)}
                >
                    Bar Chart
                </button>
            </div>
            {
                status ? (
                    <PieChart data={data}/>
                ) : (
                    <BarChart data={data} chartKey={chartKey}/>
                )
            }
        </div>
    )
}

const PieChart = ({data}) => {

    const elementRef = useRef(null);
    const chartRef = useRef(null);

    const baseData = useMemo(() => {
        const values = getAssetChartData(data);

        return [
            {
                key: 'assetAllocation-stocks',
                label: 'Stocks',
                value: values['1'],
                color: '#497CDE'
            },
            {
                key: 'assetAllocation-bonds',
                label: 'Bonds',
                value: values['2'],
                color: '#8249DE'
            },
            {
                key: 'assetAllocation-cash',
                label: 'Cash',
                value: values['3'],
                color: '#BC0021'
            },
            {
                key: 'assetAllocation-other',
                label: 'Other',
                value: values['4'],
                color: '#B6B4B4'
            },
            {
                key: 'assetAllocation-notClass',
                label: 'Not class',
                value: values['99'],
                color: '#6F6F6F'
            }
        ]
    }, [data]);

    const pieChartOptions = useMemo(() => {
        const data = baseData.map(({value, label, color}) => {
            return {
                value,
                name: label,
                itemStyle: {
                    color
                }
            }
        });
        
        return {
            tooltip: {
                trigger: 'item',
                confine: true
            },
            series: [
                {
                    name: 'Asset Allocation',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    label: {show: false},
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data
                }
            ]
        }
    }, [baseData]);

    useEffect(() => {
        const element = elementRef.current;

        if (element && !(isEmpty(pieChartOptions))) {
            const instance = echarts.init(element);
            chartRef.current = instance;
            instance.setOption(pieChartOptions);
        }
    }, [pieChartOptions]);

    return (
        <div className={styles['pie-chart']} ref={elementRef}/>
    )
};

const BarChart = ({data, chartKey}) => {

    const chartData = useMemo(() => {
        const longValues = getAssetBarChartData(data, 'L');
        const shortValues = getAssetBarChartData(data, 'S');
        const netValues = getAssetBarChartData(data, 'N');

        return [
            {
                key: 'assetAllocation-bar-stocks',
                label: 'Stocks',
                value: [longValues['1'], shortValues['1']],
                netValue: netValues['1'],
                color: '#497CDE'
            },
            {
                key: 'assetAllocation-bar-bonds',
                label: 'Bonds',
                value: [longValues['2'], shortValues['2']],
                netValue: netValues['2'],
                color: '#8249DE'
            },
            {
                key: 'assetAllocation-bar-cash',
                label: 'Cash',
                value: [longValues['3'], shortValues['3']],
                netValue: netValues['3'],
                color: '#BC0021'
            },
            {
                key: 'assetAllocation-bar-other',
                label: 'Other',
                value: [longValues['4'], shortValues['4']],
                netValue: netValues['4'],
                color: '#B6B4B4'
            },
            {
                key: 'assetAllocation-bar-notClass',
                label: 'Not class',
                value: [longValues['99'], shortValues['99']],
                netValue: netValues['99'],
                color: '#6F6F6F'
            }
        ]
    }, [data]);

    const chartRectOptions = useMemo(() => {
        return chartData.map(({value, netValue, color, label}, index) => {
            if (!(isNumber(netValue))) {
                return {width: 0}
            }

            const longValue = value[0];
            const shortValue = value[1];

            
            const x = `${new Decimal(100).minus(new Decimal(shortValue)).div(2).toNumber()}%`;
            const y = `${index * 18}%`;
            const width = `${new Decimal(longValue).div(2).plus(new Decimal(shortValue).div(2))}%`;
            const height = '15%';
            return {
                default: {
                    x,
                    y,
                    width,
                    height,
                    strokeWidth: 1,
                    stroke: color,
                    fill: '#fff'
                },
                net: {
                    x: '50%',
                    y,
                    height,
                    width: `${new Decimal(netValue).div(2)}%`,
                    fill: color,
                },
                longValue,
                shortValue,
                netValue,
                label,
                color
            }
        });
    }, [chartData]);

    return (
        <div className={styles['bar-chart-container']}>
            {
                [0, 1, 2, 3, 4].map(item => (
                    <ReactTooltip
                        key={`chart-options-rect-tooltip-${item}`}
                        anchorId={`rect-tooltip-${item}-${chartKey}`}
                        place="top"
                        variant="info"
                        style={{
                            background: '#fff', 
                            color: '#000000',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                            opacity: 1,
                            zIndex: '9999'
                        }}
                        content={() => {
                            return (
                                <Tooltip data={chartRectOptions[item]}/>
                            )
                        }}
                    />
                ))
            }
            <svg style={{fontSize: 10,  width: "100%", height:"100%"}}>
                <g>
                    <line x1="0%" y1="0" x2="0%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                    <line x1="25%" y1="0" x2="25%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                    <line x1="50%" y1="0" x2="50%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                    <line x1="75%" y1="0" x2="75%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                    <line x1="100%" y1="0" x2="100%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                </g>
                <g>
                    <text y="95%" x="0%">-100</text>
                    <text y="95%" x="25%" textAnchor="middle">-50</text>
                    <text y="95%" x="50%" textAnchor="middle">0</text>
                    <text y="95%" x="75%" textAnchor="middle">50</text>
                    <text y="95%" x="100%" textAnchor="end">100</text>
                </g>
                <g>
                    {
                        chartRectOptions.map((item, index) => (
                            <g key={`chart-options-rect-${index}`}>
                                <rect {...item.default}/>
                                <rect {...item.net}/>
                            </g>
                        ))
                    }
                </g>
                <g>
                    <rect x="0" y="0" width="100%" height="18%" opacity="0" id={`rect-tooltip-0-${chartKey}`} style={{outline: 'none'}}/>
                    <rect x="0" y="18%" width="100%" height="18%" opacity="0" id={`rect-tooltip-1-${chartKey}`} style={{outline: 'none'}}/>
                    <rect x="0" y="36%" width="100%" height="18%" opacity="0" id={`rect-tooltip-2-${chartKey}`} style={{outline: 'none'}}/>
                    <rect x="0" y="54%" width="100%" height="18%" opacity="0" id={`rect-tooltip-3-${chartKey}`} style={{outline: 'none'}}/>
                    <rect x="0" y="72%" width="100%" height="18%" opacity="0" id={`rect-tooltip-4-${chartKey}`} style={{outline: 'none'}}/>
                </g>
            </svg>
        </div>
    )
};

const Tooltip = ({data = {}}) => {

    const getValue = (value) => {
        return isNumber(value) ? returnFloat(round(value, 2)) : '0.00';
    };

    return (
        <div className={styles['tooltip']}>
            <span className={styles['tooltip-title']} style={{color: data.color}}>
                {data.label}
            </span>
            <div className={styles['tooltip-info']}>
                <div className={styles['tooltip-info-item']}>
                    <span>Long: </span>
                    <span>{getValue(data.longValue)}</span>
                </div>
                <div className={styles['tooltip-info-item']}>
                    <span>Net: </span>
                    <span>{getValue(data.netValue)}</span>
                </div>
                <div className={styles['tooltip-info-item']}>
                    <span>Short: </span>
                    <span>{getValue(data.shortValue)}</span>
                </div>
            </div>
        </div>
    )
}


export default Chart