import React from 'react';
import styles from './layout.module.css';
import logo from '../../assets/logo.svg';
import informationIcon from '../../assets/layout_action_1.png';
import fundSearchIcon from '../../assets/layout_action_1_1.png';
import fundComparisonIcon from '../../assets/layout_action_1_2.png';
import ideasIcon from '../../assets/layout_action_2.png';
import performanceRankingIcon from '../../assets/layout_action_2_1.png';
import topSellingFundsIcon from '../../assets/layout_action_2_2.png';
import highDividendPayingFundIcon from '../../assets/layout_action_2_3.png';
import allocationIcon from '../../assets/layout_action_3.png';
import portfolioXrayIcon from '../../assets/layout_action_3_1.png';
import portfolioComparisonIcon from '../../assets/layout_action_3_2.png';
import menuIcon from '../../assets/menu.svg';
import Loading from '../Loading/Loading';
import { useNavigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { LOCALES } from '../../locales.js';
import anime from 'animejs';

const { useMemo, useState, useRef, useEffect } = React;
const Layout = ({
    children,
    loading,
    activePageKey = '',
    activePageChildrenKey = '',
    setLocale = () => {},
    intl,
    isShowTabs = false
}) => {
    const navigate = useNavigate();

    const [isShowMenu, setIsShowMenu] = useState(false);

    const actionOptions = useMemo(() => {
        const options = [
            {
                key: 'information',
                // label: 'Fund Information',
                label: intl.formatMessage({id: 'layoutFundInformation'}),
                isActive: activePageKey === 'information',
                icon: informationIcon,
                list: [
                    { key: 'fundSearch', label: intl.formatMessage({id: 'layoutFundSearch'}), isActive: activePageChildrenKey === 'fundSearch', icon: fundSearchIcon, href: '/landing'},
                    { key: 'fundComparison', label: intl.formatMessage({id: 'layoutFundComparison'}), isActive: activePageChildrenKey === 'fundComparison', icon: fundComparisonIcon, href: '/fundComparison'},
                ]
            },
            {
                key: 'ideas',
                label: intl.formatMessage({id: 'layoutIdeas'}),
                icon: ideasIcon,
                list: [
                    { key: 'performanceRanking', label: intl.formatMessage({id: 'layoutPerformanceRanking'}), isActive: false, icon: performanceRankingIcon},
                    { key: 'topSellingFunds', label: intl.formatMessage({id: 'layoutTopSellingFunds'}), isActive: false, icon: topSellingFundsIcon},
                    { key: 'highDividendPayingFund', label: intl.formatMessage({id: 'layoutHighDividendPayingFund'}), isActive: false, icon: highDividendPayingFundIcon},
                ]
            },
            {
                key: 'allocation',
                label: intl.formatMessage({id: 'layoutAllocation'}),
                icon: allocationIcon,
                isActive: activePageKey === 'allocation',
                list: [
                    { key: 'portfolioXray', label: intl.formatMessage({id: 'layoutPortfolioXray'}), isActive: activePageChildrenKey === 'portfolioXray', icon: portfolioXrayIcon, href: '/portfolioXray'},
                    { key: 'portfolioComparison', label: intl.formatMessage({id: 'layoutPortfolioComparison'}), isActive: false, icon: portfolioComparisonIcon},
                ]
            },
        ];

        return options
    }, [intl]);

    const onGoto = (href) => {
        if (href) {
            navigate(href);
        }
    };

    const onGotoHome = () => {
        // window.open('https://www.icbcasia.com/ICBC/%E6%B5%B7%E5%A4%96%E5%88%86%E8%A1%8C/%E5%B7%A5%E9%93%B6%E4%BA%9A%E6%B4%B2/en', '_blank')
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles['header-top']}>
                    <div className={styles['header-top-menu']}>
                        <button onClick={() => setIsShowMenu(true)}>
                            <img src={menuIcon}/>
                        </button>
                    </div>
                    <img src={logo} onClick={onGotoHome} alt="logo"/>
                    <div className={styles['header-top-action']}>
                        <span onClick={() => setLocale(LOCALES.ENGLISH)}>ENG . </span>
                        <span onClick={() => setLocale(LOCALES.HONGKONG)}>繁 . </span>
                        <span onClick={() => setLocale(LOCALES.CHINESE)}>简</span>
                    </div>
                    
                </div>
                <div className={styles['header-bottom']}>
                    <div className={styles['header-bottom-action']}>
                        <ActionList 
                            actionOptions={actionOptions}
                            onGoto={onGoto}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                {children}
            </div>
            <div className={`${styles.footer} ${isShowTabs && styles['footer-margin']}`}>
                <section className={styles['footer-section']}>
                    <span className={styles['footer-title']}>
                        <FormattedMessage id='layoutNoteTitle'/>
                    </span>
                    <span className={styles['footer-content']}>
                        <FormattedMessage id='layoutNotContent'/>
                    </span>
                </section>
                <section className={styles['footer-section']}>
                    <span className={styles['footer-title']}>
                        <FormattedMessage id='layoutDisclaimerTitle'/>
                    </span>
                    <span className={styles['footer-content']} style={{marginBottom: '10px'}}>
                        <FormattedMessage id='layoutDisclaimerContent1'/>
                    </span>
                    <span style={{marginBottom: '10px'}}>
                        <FormattedMessage id='layoutDisclaimerContent2'/>
                    </span>
                    <span style={{marginBottom: '15px'}}>
                        <FormattedMessage id='layoutDisclaimerContent3'/>
                    </span>
                </section>
                <section className={styles['footer-section']}>
                    <span className={styles['footer-title']}>
                        <FormattedMessage id='layoutRiskTitle'/>
                    </span>
                    <span className={styles['footer-content']} style={{marginBottom: '10px'}}>
                        <FormattedMessage id='layoutRiskContent1'/>
                    </span>
                    <span style={{marginBottom: '10px'}}>
                        <FormattedMessage id='layoutRiskContent2'/>
                    </span>
                    <span style={{marginBottom: '15px'}}>
                        <FormattedMessage id='layoutRiskContent3'/>
                    </span>
                </section>

                <div className={styles['copy-right']}>
                    <div >
                        <img className={styles['copy-right-img']} alt="copyright" src="data:image/gif;base64,R0lGODlhcAAXAPMAAJmZmRAQEO/v79/f3yAgIMzMzGZmZjMzM1BQUK+vr7+/v0BAQH9/fwAAAP///wAAACwAAAAAcAAXAAAE/5BJ5apkoOrNu3cXQ1UAM5CiU1KSgFKC0cyGBgQzUYRSdo2l3kkzq8waiMrguPg4n47j7OBaNBgVK1YbbSSyV4dMmlHMcA2CdNY8Yh1WsnHOrJjZzgHAsOgjUk4zDGMZVk1wYVw0YFg4NTJNMgQCdwYMagsYAkdJiJlqAVFdXUckdR0KcWtnDC4dRWINTVahiFtheLZdFAkNoYZGDRRcDneyjMUzS1EZay4MpxoCCFKZEgZqZ18csNCzMwW6irDjFHeewcNhyTi1xDMUDXS+wg7UuRUFBzkArhsDYxrU2NDtWJxCiXDB07XwHLBdukDIIrdu4aguVhA+dDAAzRsn+v8WEZEn8ZtAcQoTYmlY5OFCYt7ARbRIEIlCaocc7GuQAYoDATu3XfSGKA3KlTOSlEsmz2U9YjL4PK1Yj9sVWZuuHoLG06e0fQFcFTTZYACXcb4Y1qOk7pACBVXWack4s2pNAA0OmAlAVACOTl7zCQpWAVJRL2dTNiiwdAOtnhqI7dujkqNMq3fwZjqGt2zgDTJqwUuA4xaNxEhnACj39q0D0kn9vRMGrYYVAwpCe1BGQyBRK4A/W/ZCik3cfQdQYxRYToqSnTqQ7dJ82NcIq11KE1UtfIOa1EcQjlEuKG/zUhq4LtZVYAalvNXhv5KXjae39uu7V8B5kb+W+rcg1Uv/XgqZA4sGd6iDxTn46TLgEDUdpoA35+hnwTFj2RJQgLvsVCBT6EDEBV4EBCPAbNeNFIsyFB6o33Yk/XZFZ5XBsyFEDh0jYhjQECABPCjOJ1ER99HUHTVJZKgFfjUKQyNSBjal40s8rpJAkNh1dkBJXUDW3XdDGRSGR2opsMRgLEl5CJW3ENBHaVhGeEcTMuYkHH7xxCimaRwulA2UIDqlYEQyMBCnilnVQFRnEH4mCWFccsFVn/XcI6AKaQmqixo9eXMopIP1hYOdXt3xhpJhmFompmGokQR/0ASgh0yNeUpVipDuw4KOnX3kkwBqTALpgHVRGiUW6nFnzBSMZDXCN5zr4OClBW+UcMKEkAXhDxQJSBAOtRUIIIEegKygQgrjUgtDQAOpgMYC364wQAtK9FAuA0JpEAEAOw=="/>
                        <a className={styles['copy-right-a']} href="https://gllt.morningstar.com/wcunxicr3v/util/disclaimer.aspx?type=copyright&LanguageId=en-GB">
                            <FormattedMessage id='layoutCopyright'/>
                        </a>
                        <span><FormattedMessage id='layoutCopyrightTips'/></span>
                    </div>
                </div>
            </div>
            {
                loading && (<Loading/>)
            }
            {
                isShowMenu && (
                    <Menu 
                        setIsShowMenu={setIsShowMenu}
                        actionOptions={actionOptions}
                        onGoto={onGoto}
                    />
                )
            }
        </div>
    )
};

const Menu = ({setIsShowMenu, actionOptions, onGoto}) => {
    const elementRef = useRef(null);

    const onStartAnimation = () => {
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    translateX: '-100%',
                    duration: 0,
                },
                {
                    translateX: 0,
                    duration: 300,
                    easing: 'linear',
                }
            ],
        })
    };

    const onEndAnimation = () => {
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    translateX: '-100%',
                    duration: 300,
                    easing: 'linear',
                }
            ],
            complete: () => {
                setIsShowMenu(false);
            }
        }) 
    };

    useEffect(() => {
        onStartAnimation();
    }, []);

    return (
        <React.Fragment>
            <div 
                ref={elementRef}
                className={styles['menu-container']} 
            >
                <div className={styles['menu-header']}>
                    Menu
                </div>
                <div className={styles['menu-body']}>
                    {
                        actionOptions.map(({key, label, list, icon, isActive}) => {
                            return (
                                <div className={styles['menu-item']} key={`${key}-menu`}>
                                    <div className={styles['menu-item-header']}>
                                        <img src={icon} alt={label}/>
                                        <span style={{color: isActive && '#BC0021', fontWeight: isActive && '600'}}>{label}</span>
                                    </div>
                                    <div className={styles['menu-item-body']}>
                                        {
                                            list.map(item => {
                                                const { key, label, icon, isActive, href } = item;
                                                return (
                                                    <div  
                                                        key={`${key}-menu`}
                                                        className={`${styles['menu-item-body-item']} ${isActive && styles['menu-item-body-item-active']}`}
                                                        onClick={() => onGoto(href)}
                                                    >
                                                        <img 
                                                            // className={styles['dropdown-icon']}
                                                            src={icon}
                                                            alt={label}
                                                        />
                                                        <span style={{color: isActive && '#BC0021', fontWeight: isActive && '500'}}>{label}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                }
                </div>
            </div>
            <div className={styles['menu-mask']} onClick={onEndAnimation}/>
        </React.Fragment>
    )
}

const ActionList = ({actionOptions, onGoto}) => {
    return (
        <>
            {
                actionOptions.map(({key, label, list, icon, isActive}) => {
                    return (
                        <div className={styles['action-item']} key={key}>
                            <div className={styles['action-item-group']}>
                                <img src={icon} alt={label}/>
                                <span className={isActive ? styles['action-item-group-label-active'] : styles['action-item-group-label']}>{label}</span>
                                <div className={isActive ? styles['action-item-active-border'] : ''}/>
                                <div className={styles['dropdown-content']}>
                                {
                                    list.map(item => {
                                        const { key, label, icon, isActive, href } = item;
                                        return (
                                            <li  
                                                key={key}
                                                className={styles['dropdown-item']}
                                                onClick={() => onGoto(href)}
                                            >
                                                <img 
                                                    className={styles['dropdown-icon']}
                                                    src={icon}
                                                    alt={label}
                                                />
                                                <span className={isActive ? styles['action-item-group-label-active'] : ''}>{label}</span>
                                            </li>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
};

export default injectIntl(Layout);