import React from 'react';
import { get } from 'lodash';
import { 
    getAssetAllocationValue, getTopStockRegionsValue, 
    getStockSectorValue, getFixedIncomeSectorValue,
    getTopHoldingsValue
} from '../../../utils/details';
import increaseIcon from '../../../assets/details/increase.svg';
import decreaseIcon from '../../../assets/details/decrease.svg';
import newIcon from '../../../assets/details/new.svg';
import styles from './portfolio.module.css';

const { useMemo } = React;

const Portfolio = ({
    data,
    fundAssetClassId,
    fundAssetClassName
}) => {

    const componentOptions = useMemo(() => {
        const equityStyle = {
            title: 'Morningstar Style Box®',
            key: 'equityStyle'
        };
        const fixedIncomeStyle = {
            title: '',
            key: 'fixedIncomeStyle'
        };
        const assetAllocation = {
            title: 'Asset Allocation%',
            key: 'assetAllocation'
        };
        const topStockRegions = {
            title: 'Top Stock Regions',
            key: 'topStockRegions'
        };
        const stockSector = {
            title: 'Top Sectors',
            key: 'stockSector'
        };
        const fixedIncomeSector = {
            title: '',
            key: 'fixedIncomeSector'
        };
        const topHoldings = {
            title: 'Top Holdings',
            key: 'topHoldings',
            span: 1
        };

        if (fundAssetClassId === '$BCG$ALLOC') { //Allocation
            return [
                {...equityStyle},
                {...fixedIncomeStyle},
                {...assetAllocation},
                {...topStockRegions},
                {...stockSector},
                {...fixedIncomeSector},
                {...topHoldings}
            ];
        }

        if (fundAssetClassId === '$BCG$EQUTY') {//Equity
            return [
                {...equityStyle},
                {...topHoldings, span: ''},
                {...assetAllocation},
                {...topStockRegions},
                {...stockSector, title: 'Top Stock Sectors'},
            ];
        }

        return [
            {...fixedIncomeStyle, title: 'Morningstar Style Box®'},
            {...assetAllocation},
            {...fixedIncomeSector, title: 'Top Fixed Income Sectors'},
            {...topHoldings}
        ]
        
    }, [fundAssetClassId, fundAssetClassName]);

    const equityStyleValue = useMemo(() => {
        return {
            title: 'Equity Style',
            rightTitle: 'Size',
            rightText: ['Large', 'Mid', 'Small'],
            bottomTitle: 'Style',
            bottomText: ['Value', 'Blend', 'Growth'],
            color: '#BC0021',
            value: get(data, 'detail.EquityStyleBox')
        }
    }, [data]);

    const fixedIncomeStyleValue = useMemo(() => {
        return {
            title: 'Fixed Income Style',
            rightTitle: 'Credit Quality',
            rightText: ['High', 'Med', 'Low'],
            bottomTitle: 'Interest Rate Sensitivity',
            bottomText: ['Low', 'Med', 'High'],
            color: '#E96610',
            value: get(data, 'detail.BondStyleBox')
        }
    }, [data]);

    const assetAllocationValue = useMemo(() => {
        return [
            {
                isHeader: true,
                key: 'assetAllocationKey',
                label: '',
                values: ['Long', 'Short	', 'Net']
            },
            {
                key: 'stocks',
                label: 'Stocks',
                values: getAssetAllocationValue(data, 0),
            },
            {
                key: 'bonds',
                label: 'Bonds',
                values: getAssetAllocationValue(data, 1),
            },
            {
                key: 'cash',
                label: 'Cash',
                values: getAssetAllocationValue(data, 2),
            },
            {
                key: 'other',
                label: 'Other',
                values: getAssetAllocationValue(data, 3),
            },
        ]
    }, [data]);

    const topStockRegionsValue = useMemo(() => {
        return getTopStockRegionsValue(data);
    }, [data]); 

    const stockSectorValue = useMemo(() => {
        return getStockSectorValue(data);
    }, [data]);

    const fixedIncomeSectorValue = useMemo(() => {
        return getFixedIncomeSectorValue(data);
    }, [data]);

    const topHoldingsValue = useMemo(() => {
       return getTopHoldingsValue(data, fundAssetClassId);
    }, [data, fundAssetClassId]);

    const getComponentClass = (key, fundAssetClassId) => {
        if (key !== 'topHoldings') return ''

        if (fundAssetClassId === '$BCG$EQUTY') {
            return styles['item-holdings-equity']
        }
        return styles['item-holdings']
    };

    return (
        <div className={styles.container}>
            {
                componentOptions.map(({key, title}) => (
                    <div
                        key={`item-${key}`}
                        className={`${styles.item} ${getComponentClass(key, fundAssetClassId)}`}
                    >
                        <span className={styles['item-title']}>
                            {title}
                        </span>
                        {
                            key === 'equityStyle' && (
                                <StyleBox {...equityStyleValue}/>
                            )
                        }
                        {
                            key === 'fixedIncomeStyle' && (
                                <StyleBox {...fixedIncomeStyleValue}/>
                            )
                        }
                        {
                            key === 'assetAllocation' && (
                                <AssetAllocation data={assetAllocationValue}/>
                            )
                        }
                        {
                            key === 'topStockRegions' && (
                                <ModularList data={topStockRegionsValue}/>
                            )
                        }
                        {
                            key === 'stockSector' && (
                                <ModularList data={stockSectorValue}/>
                            )
                        }
                        {
                            key === 'fixedIncomeSector' && (
                                <ModularList data={fixedIncomeSectorValue}/>
                            )
                        }
                        {
                            key === 'topHoldings' && (
                                <TopHoldings data={topHoldingsValue}/>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
};

const StyleBox = ({
    title,
    rightText,
    rightTitle,
    bottomText,
    bottomTitle,
    color,
    value
}) => {

    const box = useMemo(() => {
        return Array.from(Array(9), (e, index) => index + 1)
    }, []);

    return (
        <div className={styles['style-box']}>
            <span className={styles['style-box-title']}>{title}</span>
            <div className={styles['style-box-group']}>
                {
                    box.map(item => (
                        <div 
                            className={styles['style-box-group-item']}
                            key={`${item}-${title}`}
                            style={{backgroundColor: value === item && color}}
                        />
                    ))
                }
                <div className={styles['group-right-info']}>
                    <div className={styles['group-right-info-left']}>
                        {
                            rightText.map(text => (
                                <span key={`left-${text}`}>{text}</span>
                            ))
                        }
                    </div>
                    <div className={styles['group-right-info-right']}>
                        <span>{rightTitle}</span>
                    </div>
                </div>
                <div className={styles['group-bottom-info']}>
                    {
                        bottomText.map(text => (
                            <span key={`bottom-${text}`}>{text}</span>
                        ))
                    }
                </div>
            </div>
            <span className={styles['style-box-bottom-tips']}>{bottomTitle}</span>
        </div>
    )
};

const AssetAllocation = ({data = []}) => {
    return (
        <div className={styles['asset-main']}>
            {
                data.map(({key, label, values, isHeader}) => (
                    <div
                        className={`${styles['asset-main-item']} ${isHeader && styles['asset-main-item-tips']}`}
                        key={`${key}-asset`}
                    >
                        <span className={styles['asset-item-group']}>
                            {label}
                        </span>
                        {
                            values.map((item, index) => (
                                <div 
                                    key={`assetData-${key}-${index}`} 
                                    className={`${styles['asset-item-group']} ${styles['asset-item-group-data']}`}
                                >
                                    {item}
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
};

const ModularList = ({data = []}) => {
    return (
        <div className={styles['asset-main']}>
            {
                data.map(({label, isHeader, value, key, icon}) => (
                    <div
                        className={`${styles['asset-main-item']} ${isHeader && styles['asset-main-item-tips']}`}
                        key={key}
                        style={{paddingLeft: isHeader && '0'}}
                    >
                        <div className={styles['asset-item-group']}>
                            {
                                icon  && (<img src={icon} style={{width: 24, height: 24, marginLeft: 6}}/>)
                            }
                            <span>{label}</span>
                        </div>
                        <span>{value}</span>
                    </div>
                ))
            }
        </div>
    )
};

const TopHoldings = ({data = []}) => {
    return (
        <div className={styles['asset-main']}>
            {
                data.map(({label, isHeader, value, desc, counter, isShowCountry, status}, index) => (
                    <div
                        className={`${styles['asset-main-item']} ${styles['asset-main-item-holdings']} ${isHeader && styles['asset-main-item-tips']}`}
                        key={`${index}-topHoldings`}
                        style={{paddingLeft: isHeader && '0', fontSize: isHeader && '14px'}}
                    >
                        <div className={styles['asset-item-group']}>
                            {
                                !isHeader && status && (
                                    <img src={status === 'new' ? newIcon : status === 'decrease' ? decreaseIcon : increaseIcon}/>
                                )
                            }
                            <span 
                                style={{
                                    marginLeft: status ? 0 : 24
                                }}
                                title={label}
                            >
                                {label}
                            </span>
                        </div>
                        <span 
                            className={styles['asset-item-group-holdings']}
                            style={{
                                flexDirection: 'row',
                                transform: isHeader && 'translateX(7px)',
                            }}
                            className={styles['asset-main-item-holdings-sector']}
                        >
                            {isShowCountry ? counter : desc}
                        </span>
                        <span 
                            className={styles['asset-item-group-holdings']}
                            style={{width: '15%'}}
                        >
                            {value}
                        </span>
                    </div>
                ))
            }
            {
                <div className={styles['holdings-action']}>
                    <div className={styles['holdings-action-item']}>
                        <img src={increaseIcon}/>
                        <span>Increase</span>
                    </div>
                    <div className={styles['holdings-action-item']}>
                        <img src={decreaseIcon}/>
                        <span>Decrease</span>
                    </div>
                    <div className={styles['holdings-action-item']}>
                        <img src={newIcon}/>
                        <span>New since last portfolio</span>
                    </div>
                </div>
            }
        </div>
    )
};
 
export default Portfolio;