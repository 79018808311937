import React from 'react'
import {
    createBrowserRouter,
    RouterProvider,
    useNavigate,
} from "react-router-dom";
import { isEqual } from 'lodash';
import { IntlProvider } from "react-intl";
import Landing from './routes/Landing/Landing';
import Details from './routes/Details/Details';
import PortfolioXray from './routes/PortfolioXray/PortfolioXray';
import FundComparison from './routes/FundComparison/FundComparison';
import { LOCALES } from './locales.js';
import { messages } from './messages.js';
import './app.css';

const { useEffect, useState } = React;
function App() {
    
    const [locale, setLocale] = useState(LOCALES.ENGLISH);
    const [isStart, setIsStart] = useState(false);

    const Root = () => {
        const navigate = useNavigate();
    
        useEffect(() => {
            navigate('/landing');
        }, []);
    
        return (
            <div></div>
        )
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root/>
        },
        {
            path: '/landing',
            element: (
                <Landing setLocale={setLocale} isStart={isStart} setIsStart={setIsStart}/>
            )
        },
        {
            path: '/details',
            element: (
                <Details setLocale={setLocale}/>
            )
        },
        {
            path: '/portfolioXray',
            element: <PortfolioXray setLocale={setLocale}/>
        },
        {
            path: '/fundComparison',
            element: <FundComparison setLocale={setLocale}/>
        }
    ]);

    return (
        <div className="app" style={{
            fontFamily: (locale === LOCALES.ENGLISH || locale === LOCALES.CHINESE) ? 'Inter' :'Noto Sans HK'
        }}>
            <IntlProvider
                messages={messages[locale]} 
                locale={locale} 
                defaultLocale={LOCALES.ENGLISH}
            >
                <RouterProvider router={router}/>
            </IntlProvider>
        </div>
    );
}

export default App;
