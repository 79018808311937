import React from 'react';
import styles from './table.module.css';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'
import { FormattedMessage } from 'react-intl';

const { useMemo, useState, useEffect } = React;

const Table = ({
    columns,
    data = [],
    totalCount = 0, 
    totalData,
    onFetchData = () => {},
    controlledPageIndex,
    onSelectRow = () => {}
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setSortBy,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds, sortBy },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            initialState: { 
                pageIndex: 0, 
                pageSize: 10,
                sortBy: [
                    {
                        id: 'ReturnM0',
                        desc: true
                    }
                ]
            },
            pageCount: totalCount,
            autoResetSelectedRows: false,
            getRowId: (row) => {
                return row.SecId
            },
            useControlledState: state => {
                return useMemo(() => {
                    return {
                        ...state,
                        // pageIndex: controlledPageIndex,
                    }
                },[state, controlledPageIndex])
            },
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()}/>
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );

    const [isFirstRequest, setIsFirstRequest] = useState(true);
    
    const handleNextPage = () => {
        if (pageIndex + 1 === pageCount) return;
        nextPage();
    };
    
    const handlePreviousPage = () => {
        if (pageIndex === 0) return; 

        previousPage();
    };

    const handleGogoPage = (goPageIndex) => {
        if (goPageIndex === pageIndex) return

        gotoPage(goPageIndex);
    };

    const handleChangeRowsPerPage = (value) => {
        setPageSize(value)
    };

    const handleMultiSortBy = (column, setSortBy) => {
        const { isSortedDesc, id } = column;
        const sortDescValue = isSortedDesc === undefined ? true : !isSortedDesc;
        
        if (id === 'selection') return

        setSortBy([{ 
            id,
            desc: sortDescValue
        }]);
    };

    useEffect(() => {
        onSelectRow(selectedRowIds, selectedFlatRows);
    }, [selectedRowIds, selectedFlatRows]);

    useEffect(() => {
        if (isFirstRequest) {
            setIsFirstRequest(false);
            return;
        }
        onFetchData(pageIndex, pageSize, sortBy);
    }, [pageIndex, pageSize, sortBy]);

    useEffect(() => {
        if (pageIndex !== 0 && totalData === 0) {
            handleGogoPage(0);
        }
        
    }, [pageIndex, totalData])

    return (
        <>
        <div className={styles.container}>
            <table {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map((headerGroup) => (
                            <tr 
                                {...headerGroup.getHeaderGroupProps()} 
                                className={styles.tr}
                            >
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th 
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={styles.th} 
                                                nowrap="nowrap"
                                                onClick={() => handleMultiSortBy(column, setSortBy)}
                                                style={{
                                                    paddingLeft: column.id === 'selection' && '6px'
                                                }}
                                            >
                                                <div className={styles.header}>
                                                    {column.render('Header')}
                                                </div>
                                                <div 
                                                    className={styles['table-sort']}
                                                    style={{
                                                        borderTop: !column.isSorted && 'none',
                                                        // marginLeft: index === 0 ? 16 : 0
                                                    }}
                                                >
                                                    <div style={{position: 'relative'}}>
                                                        {
                                                            column.isSorted ? column.isSortedDesc ? (
                                                                <div className={styles['table-sort-down']}/>
                                                            ) : (
                                                                <div className={styles['table-sort-up']}/>
                                                            ) : ''}
                                                    </div>
                                                    
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
            <tbody {...getTableBodyProps()}>
                {
                    page.map((row, i) => {
                        prepareRow(row)
                        return (
                            // <div className={styles.row} key={`${row.id}-${i}`}>
                                <tr 
                                    {...row.getRowProps}
                                    className={`${styles.tr} ${row.isSelected && styles['tr-active']}`}
                                    key={`${row.id}-${i}`}
                                >
                                {
                                    row.cells.map((cell) => {
                                        const { id } = cell.column;
                                        return (
                                            <td 
                                                className={`
                                                    ${styles.td} 
                                                    ${id === 'selection' && styles['td-selection']} 
                                                    ${id === 'custominstitutionsecurityid' && styles['td-name']}
                                                    ${(id === 'FS' || id === 'PR' || id === 'KF' || id === 'AR' || id === 'IR') && styles['td-document']}
                                                `} 

                                                {...cell.getCellProps()}
                                            >
                                                {
                                                    id !== 'selection' && (
                                                        <div className={styles['td-info']}>
                                                            {cell.column.Header}
                                                        </div>
                                                    )
                                                }
                                                <div className={`${styles['td-cell']} ${cell.row.isSelected && styles['td-cell-active']}`}>
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                                </tr>
                            // </div>
                        )
                    })
                }
            </tbody>
            </table>
        </div>
        <PaginationActions
            gotoPage={handleGogoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={handlePreviousPage}
            nextPage={handleNextPage}
            pageCount={pageCount}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalData={totalData}
            setPageSize={handleChangeRowsPerPage}
        />
        </>
    )
};

export const PaginationActions = ({
    gotoPage,
    // canPreviousPage,
    // canNextPage,
    previousPage,
    nextPage,
    pageCount,
    // pageOptions,
    setPageSize,
    pageIndex,
    pageSize,
    totalData
}) => {
    
    const start = useMemo(() => {
        if (pageIndex === 0) {
            return 1
        }

        return ((pageIndex) * pageSize) + 1;
    }, [pageIndex, pageSize]);

    const end = useMemo(() => {
        if (pageIndex === 0) {
            return pageSize
        }
        const max = (pageIndex) * pageSize + pageSize;
        return max > totalData ? totalData : max;
    }, [pageIndex, pageSize, totalData]);

    const options = useMemo(() => {
        
        if (pageCount < 3) {
            let arr = [];
            for (let i = 1; i <= pageCount; i++) {
                arr.push(i);
            }
            return arr;
        }

        const newIndex = pageIndex + 1;
        if (newIndex <= 2 ) {
            return Array.from(Array(3), (e, index) => index + 1)
        }
        if (newIndex + 1 > pageCount) {
            let arr = [];
            for(let i = - 2; i < 1; i++) {
                arr.push(pageCount + i)
            }
            return arr
        }
        let arr = [];
        for(let i = - 1; i < 2; i++) {
            arr.push(newIndex + i)
        }
        return arr;
    }, [pageIndex, pageCount]);

    return (
        <div className={styles.pagination}>
            <div className={styles['pagination-bottom']}>
                <span>
                    <FormattedMessage 
                        id='paginationTips'
                        values={{
                            start,
                            end,
                            totalData
                        }}
                    />
                </span>
                <div className={styles['pagination-select-group']}>
                    <span>
                        <FormattedMessage id='paginationShow'/>
                    </span>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {
                            [10, 20, 50].map(pageSize => (
                                <option 
                                    key={`table_select_${pageSize}`} 
                                    value={pageSize}
                                >
                                    {pageSize}
                                </option>
                            ))
                        }
                    </select>
                    <span>
                        <FormattedMessage id='paginationResult'/>
                    </span>
                </div>
            </div>
            <div className={styles['pagination-top']}>
                <span
                    tabIndex={1}
                    role="button"
                    onClick={() => gotoPage(0)}
                    className={styles['pagination-top-default']}
                >
                    <FormattedMessage id='paginationFirst'/>
                </span>
                <span
                    tabIndex={1}
                    role="button"
                    onClick={previousPage}
                    className={styles['pagination-top-default']}
                >
                    <FormattedMessage id='paginationPrevious'/>
                </span>
                {
                    options.map(item => (
                        <span 
                            onClick={() => gotoPage(item - 1)}
                            key={`pagination_${item}`}
                            className={`${styles['pagination-number']} ${(pageIndex + 1) === item && styles['pagination-number-active']}`}
                        >
                            {item}
                        </span>
                    ))
                }
                <span
                    tabIndex={1}
                    role="button"
                    onClick={nextPage}
                    className={styles['pagination-top-default']}
                >
                    <FormattedMessage id='paginationNext'/>
                </span>
                <span 
                    tabIndex={1}
                    role="button"
                    onClick={() => gotoPage(pageCount - 1)}
                    className={styles['pagination-top-default']}
                >
                    <FormattedMessage id='paginationLast'/>
                </span>
            </div>     
        </div>
    )
};

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;
        
        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <input 
                type="checkbox" 
                ref={resolvedRef} {...rest}
                className={styles.checkbox}
            />
        )
    }
)

export default Table;