import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './group.module.css';

const CheckboxGroup = ({
    options = [],
    onChange = () => {}
}) => {

    return (
        <div className={styles.group}>
            {
                options.map(({key, messageId, icon, checked},index) => {
                    return (
                        <div className={styles.container} key={key}>
                            <div className={styles.item}>
                                <img src={icon}/>
                            </div>
                            <div className={styles.item}>
                                <FormattedMessage id={messageId}/>
                            </div>
                            <div className={styles.item}>
                                <input 
                                    className={styles.checkbox} 
                                    checked={checked}
                                    name={key} 
                                    type="checkbox"
                                    onChange={(e) => onChange(e, index)}
                                />
                            </div>
                        </div>                            
                    )
                })
            }
        </div>
    )
};

export default CheckboxGroup;