import React from 'react';
import * as echarts from 'echarts';
import { isEmpty, round, get } from 'lodash';
import { returnFloat } from '../../../utils/details';
import dayjs from 'dayjs';
import styles from './chart.module.css';

const { useEffect, useState, useMemo, useRef } = React;
const Chart = ({data}) => {

    const elementRef = useRef(null);
    const chartRef = useRef(null);

    const [chartData ,setChartData] = useState({});

    const onRequest = async (code, currencyId, type) => {
        try {
            const response = await fetch(encodeURI(`api2/v1/agent/timeseries/cumulativereturn?code=${code}&currencyId=${currencyId}`), {// /v1/agent
                method: 'GET'
            });
            const res = await response.json();
            return {
                data: filterData(res),
                type
            }
        }catch (err) {
            console.log(err)
            return false
        }
    };

    const filterData = (res) => {
        const currentData = get(res, 'TimeSeries.Security[0].GrowthSeries[0].HistoryDetail') || []
        return currentData.map(item => {
            return [item.EndDate, item.Value]
        }).filter((item) => {
            const date = item[0];
            return dayjs(date).isAfter(dayjs('2019-01-01')) || dayjs(date).isSame(dayjs('2019-01-01'));
        }).map(item => {
            // return [item[0], item[1]]
            return [item[0], round(item[1], 2)]
        });
    };

    const options = useMemo(() => {
        const series = [];
        
        if (isEmpty(chartData)) return [];
        const baseObj = {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: '#E991A0'
                    },
                    {
                        offset: 1,
                        color: 'rgba(238, 126, 145, 0.2)'
                    }
                ])
            }
        };
        const {fund, category, benchmark} = chartData;
        if (!(isEmpty(fund))) {
            series.push({
                ...baseObj,
                name: 'Fund',
                data: fund,
                itemStyle: {color: '#CE0000'}
            });
        }

        if (!(isEmpty(category))) {
            series.push({
                ...baseObj,
                name: 'Category',
                data: category,
                itemStyle: {color: '#FF9A63'}
            });
        }

        if (!(isEmpty(benchmark))) {
            series.push({
                ...baseObj,
                name: 'Benchmark',
                data: benchmark,
                itemStyle: {color: '#99CF00'}
            })
        }
        return {
            legend: {
                data: ['Fund', 'Category', 'Benchmark']
            },
            dataZoom: {
                start: 0,
                end: 20
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                formatter: (value) => {
                    const item = value.map((item) => {
                        return `
                            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px; color: #333333">
                                <div style="display:flex; justify-content: space-between; align-items: center;">
                                    <span style="border-radius:10px;width:10px;height:10px; background: ${item.color};margin-right: 4px;"></span>
                                    <span style="font-size: 14px; line-height: 16px;">
                                        ${item.seriesName}
                                    </span>
                                </div>
                                <span style="font-weight: 700; font-size: 14px; line-height: 16px;">
                                    ${returnFloat(item.value[1])}
                                <span>
                            </div>
                        ` 
                    }).reduce((next, current, index) => {
                        if (index === 0) {
                            return next;
                        }
                        return `${next}${current}`
                    });
                    return `
                        <div
                            style="display: flex;flex-direction: column;color: #333333"
                        >
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="font-size: 14px; line-height: 16px;">${value[0].axisValueLabel}</span>
                                <span style="font-weight: 700; margin-left: 16px; font-size: 14px; line-height: 10px;">Total Growth<span>
                            </div>
                            ${item}
                        </div>
                    `
                }
            },
            xAxis: {
                type: 'time',
                splitNumber: 5,
                boundaryGap: ['0.5%', '0%'],
            },
            yAxis: {
                type: 'value',
                scale: true
            },
            series: series
        }
    }, [chartData]);

    const asyncRequest = async (data) => {
        const {benchmarkChatCode, categoryChatCode, fundChatCode, securities} = data;
        const fund = await onRequest(
            `${fundChatCode}`,
            securities.Currency.Id,
            'fund'
        ); 

        const category = await onRequest(
            `${categoryChatCode}`,
            securities.Currency.Id,
            'category'
        );

        const benchmark = await onRequest(
            `${benchmarkChatCode}`,
            securities.Currency.Id,
            'benchmark'
        );

        if (fund && category && benchmark) {
            setChartData({
                [fund.type]: fund.data,
                [category.type]: category.data,
                [benchmark.type]: benchmark.data 
            });
        }
    };

    useEffect(() => {
        const element = elementRef.current;

        if (element && !(isEmpty(options))) {
            const instance = echarts.init(element);
            chartRef.current = instance;
            instance.setOption(options);
        }
    }, [options]);

    useEffect(() => {
        asyncRequest(data) 
    }, [data]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (!(isEmpty(chartRef.current))) {
                chartRef.current.resize();
            }
        })
    }, [])

    return (
        <div className={styles.container} ref={elementRef}>
            {/* <img 
                style={{width: '100%'}}
                src="https://00243.eci0254d.eas.morningstar.com/chart?&cht=lc&chs=499x200&chof=s&chfts=13&chd=e:MzPxQJRiRvQmSbTLTfTCUBUOWHV6U6NWRgTDVOZtcyayashEmOn0qKqDtetxvqulwusvwQvsyJrio3o.i5iydGgFcsVaWYarXBeob5hjgS________________,MzQlR9SxUHRlUHU5UIUpVlWgX0XPTjKoPQRPStVlYIWZVXbRd8eAfAgBiijekPlAmRjnl5kjmgiYg4hwdnc8X7bMZFT3V.ZOXpcOabbdby________________,________________________________________________mOlqmVn5qLrTr9tBuZryuMs-vNrUpsqBkikdfAjvgZZ4cuhcfOkcidk1l9________________,,&chco=CE0000|FF9A63|99CF00&chxt=x,y&chxr=y,0,4095&chmg=0,0,0,70&chgx=y,1,2,AAAAAA,1|x,1,0,CCCCCC,1&chft=Trebuchet%20MS&chm=VLG,D0D3DE,0,0|H,000000,0,0,1&chxl=0:|||||||||||||%20||||||||||||%20||||||||||||%20||||||||||||%20||||||||||||%20|1:85|100|115|130|145|160"/> */}
        </div>
    )
};

export default Chart